@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@200;300;400;500;600;700;800;900&family=Inter:wght@300;400;500;600;700;800;900&display=swap');

body {
  background-color: #F8F6F2!important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-family: 'Epilogue', 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
  font-size: 40px !important;
  line-height: 64px !important;
  font-weight: 600 !important;
}

h3 {
  font-family: 'Epilogue', 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
  font-size: 24px !important;
  line-height: 40px !important;
  font-weight: 600 !important;
}

h4 {
  font-family: 'Epilogue', 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
  font-size: 18px !important;
  line-height: 22px !important;
  font-weight: 600 !important;
}

h5 {
  font-family: 'Epilogue', 'Roboto', 'Helvetica Neue', 'sans-serif' !important;
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 600 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #2979FF;
  cursor: pointer;
  text-decoration: none;
}

.MuiDataGrid-root {
  border: none!important;
}

div.success-snack {
  background-color: #029094!important;
}

@keyframes backgroundColorPalette {
  0% {
    background: #fd201a;
  }
  50% {
    background: #740000;
  }
  100% {
    background: #fd201a;
  }

}

.urgent-calls {
  animation-name: backgroundColorPalette;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.deactivated-data-grid-row {
  background-color: #ffffcc;
}

.warning-data-grid-row {
  background-color: #ffa5a0;
}

ul.MuiAutocomplete-listbox {
  overflow-x: hidden;
}

div.MuiTabPanel-root {
  padding: 24px 0;
}
